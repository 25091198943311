var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { api } from "../web";
export function reloadPage() {
    window.location.reload();
}
export function downloadRequestFile(url, method = "GET") {
    return __awaiter(this, void 0, void 0, function* () {
        const r = yield api(url, {}, { method });
        let filename = "downloaded-file";
        const contentDisposition = r.headers.get("Content-Disposition");
        if (contentDisposition) {
            const match = contentDisposition.match(/filename="?(.+?)"?$/);
            if (match === null || match === void 0 ? void 0 : match[1]) {
                [, filename] = match;
            }
        }
        const blob = yield r.blob();
        const objectUrl = URL.createObjectURL(blob);
        const a = window.document.createElement("a");
        a.style.display = "none";
        a.href = objectUrl;
        a.download = filename;
        window.document.body.append(a);
        a.click();
        a.remove();
        return URL.revokeObjectURL(objectUrl);
    });
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export function sendData(url, data, opts) {
    return __awaiter(this, void 0, void 0, function* () {
        const formData = new FormData();
        for (const name in data) {
            if (data.hasOwnProperty(name)) {
                const element = data[name];
                if (Array.isArray(element))
                    element.forEach((el) => formData.append(name, JSON.stringify(el)));
                else if (typeof element === "boolean")
                    formData.append(name, element ? "True" : "False");
                else if (element !== null)
                    formData.append(name, element.toString());
            }
        }
        formData.append("csrfmiddlewaretoken", window.csrf);
        return yield fetch(url, Object.assign({ method: "POST", body: formData }, opts));
    });
}
const joinQueryIfExists = (obj) => `?${Object.entries(obj)
    .filter(([, v]) => v !== undefined && v !== null)
    .map(([k, v]) => `${k}=${v.toString()}`)
    .join("&")}`;
export function api(url, queryParameters, fetchParametes) {
    let fullUrl = url;
    if (queryParameters)
        fullUrl += joinQueryIfExists(queryParameters);
    return fetch(fullUrl, fetchParametes).then((response) => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json();
        }
        else if (
        // TODO Adapt to handle all kind of downloadable files
        contentType &&
            contentType.indexOf("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") !== -1) {
            return response;
        }
        else {
            return response.text(); // TODO fix behavior when json not returned
        }
    });
}
export const get = (url, queryParameters) => api(url, queryParameters);
export const remove = (url, queryParameters) => api(url, queryParameters, {
    method: "DELETE",
    headers: {
        "X-CSRFToken": window.csrf,
    },
});
export const apiWithBody = (url, data, queryParameters, method = "POST") => {
    const withFormData = data instanceof FormData;
    let headers = {
        "X-CSRFToken": window.csrf,
    };
    if (!withFormData)
        headers = Object.assign(Object.assign({}, headers), { Accept: "application/json", "Content-Type": "application/json" });
    return api(url, queryParameters, {
        method,
        body: withFormData ? data : JSON.stringify(data),
        headers,
    });
};
export const post = (url, data, queryParameters) => apiWithBody(url, data, queryParameters);
export const patch = (url, data, queryParameters) => apiWithBody(url, data, queryParameters, "PATCH");
